import { BsBrightnessHigh } from 'react-icons/bs'
import { MdOutlineBrightness2 } from 'react-icons/md'

const SalonCard = ({i, d}) => {


    return (
        <>
        <div className='item-wrap salon-wrap' key={ i }>
            <div className={`left item-img${i}`}></div>
          
                
                <div className='right sal-morn'>
                    <h4 className='salon'>{d.Title}</h4>
                    <h4 className='salon sub'>{d.Speaker}</h4>
                    <p className='sub sal1'>
                      <MdOutlineBrightness2 style={{margin:'0px 5px 0px 0px', position:'absolute', top:'5px', left:'10px', color:'#413d45'}}/>
                        {d.Date1}
                    </p>
                    <p className='sub sal2'>
                      <BsBrightnessHigh style={{margin:'0px 5px 0px 0px', position:'absolute', top:'5px', left:'10px', color:'#413d45'}}/>
                        {d.Date2}
                    </p>
                    <p className='salblurb'>{d.Blurb1}</p>
                    <p className='salblurb'>{d.Blurb2}</p>
                </div>

  
        </div>
        </>
    )
}

export default SalonCard
import SalonCard from '../components/SalonCard'
import Checkout from '../components/Checkout'
import salonData from '../api/salon.js?v22'
import { AiOutlineGift } from 'react-icons/ai'
import { useState, useEffect } from 'react'


const Salon = () => {

  let open = new Date('2024-04-26');

  let url = window.location;

  let params = new URLSearchParams(url.search);
  let check = params.get('q');
  let paySuccess = params.get('success');
  let total = 199;
  let payLink = 'https://book.stripe.com/5kA02pfTWgFqdvW28g';

  const [season, setSeason] = useState(salonData);

  const soldout = true;


  season.sort((a,b)=> {
    return new Date(a.DateJS) - new Date(b.DateJS);
  })

  const [myList, setMyList] = useState([{
    "ID": 0,
    "Title": "Spring Salon",
    "Date": "20-24 May",
    "DateJS": "2024-05-20",
    "Type": "#",
    "Cost": 199,
    "PriceID": "#",
    "Blurb": "#",
    "Booking": true,
    "New": false
  }]);


  const [checkout, setCheckout] = useState(false);

  const today = new Date();

  
  useEffect(()=>{
    if (paySuccess)
    {
      setCheckout(true);
    }

  },[paySuccess]);



    return (
         <>
      <div className='banner'></div>
      <section className='line'>
         <div className='intro'>
           <h2>Spring Salon</h2>
            <p>Join Write & Shine for our Spring Salon, a week of writing and inspiration from 20-24 May.</p>
            <p>We have a wonderful line up of speakers—author and gardener Marchelle Farrell, award-winning novelist Charlotte Mendelson and Lillie O'Brien, the inspiring founder of London Borough of Jam!</p>
            <p>The week of workshops and talks is inspired by nature, green spaces and gardening, with events themed around the relationship between creativity and growing, and the joy of fruits, flowers and plants.</p>
            <p>Our Spring Salon offers four distinct events, each in two halves. Every evening, we'll host thoughtful talks from artists and in the morning, we'll pick up the threads from the night before with a writing workshop on the same theme. An optional writing hour will follow the morning sessions.</p>
            <p>It's £199 for the whole week with limited places. All events take place online on Zoom (UK time), so you can take part wherever you're based.</p>
              {
      check === 'garden' ?
      <button className='btncenter book' onClick={()=>(setCheckout(true))}>Book now!</button>
      : open > today ?
      <button className='btncenter book'>Opening soon!</button>
      : soldout ?
        <button className='btncenter soldout'>Sold out</button>
      :
      <button className='btncenter book' onClick={()=>(setCheckout(true))}>Book now!</button>
      }

         </div>
    </section>
    <section className='km'>
      <h3 className='center'>Become a member</h3>
      <div className='circles-wrap'>
        <div className='left'>
        <div className='circle-km-shadow'></div>
          <div className='circle-km-img img-0'></div>
        </div>
        <div className='right'>
          <p>This salon is included in our memberships, which start at £99 per month. Joining as a member gives you access to Write & Shine's entire programme as part of a monthly subscription.</p>
        </div>
      </div>
      <a href='https://membership.write-and-shine.com'>
        <button className='btncenter book'>Explore memberships</button>
      </a>
    </section>
    
    <section>

    {
      check === 'garden' ?
        <button className='book sticky' onClick={()=>(setCheckout(true))}>Book now - £199</button>
        : open > today ?
        <></>
        : soldout ?
        <></>
        :
        <button className='book sticky' onClick={()=>(setCheckout(true))}>Book now - £199</button>
    }



    <section className='main'>
      { season.map((d,i)=>
         d.Booking &&
        <SalonCard d={d} key={i} i={i} />
      )}
    </section>


    <section className='km'>
      <h3 className='center'>Give this salon as a gift</h3>
      <div className='circles-wrap'>
        <div className='left'>
        <div className='circle-km-shadow'></div>
          <div className='circle-km-img img-1'></div>
        </div>
        <div className='right'>
          <p>Why not gift our Salon to a friend or loved one? It'll provide time and space for them to focus on their writing. Gifts are sent immediately to the recipient with your personal message.</p>
        </div>
      </div>


{
check === 'lovebirds' ?

    <a href='https://buy.stripe.com/28oaH3236fBmdvW5kt'>
    <button className='btncenter book iconpad'>Send a gift <AiOutlineGift size={25} style={{position:'absolute', top:'11px', right:'11px'}} /></button>
    </a>

  : open > today ?
    <button className='btncenter book'>Coming soon!</button>
  
    : soldout ?

      <button className='btncenter soldout'>Sold out</button>

    :
        <a href='https://buy.stripe.com/28oaH3236fBmdvW5kt'>
          <button className='btncenter book iconpad'>Send a gift <AiOutlineGift size={25} style={{position:'absolute', top:'11px', right:'11px'}} /></button>
        </a>
  }

    </section>


<section className='main'>

<p className='center' style={{padding:'10px 30px 10px 30px'}}>Please note: No refunds available. Transfers to future workshops or courses may be possible with more than 48 hours' notice. Programme is subject to amendments and additions.</p>

</section>



</section>


 


  { checkout &&
   <>
   <div className='fixedback' onClick={()=>setCheckout(false)}></div>
   <Checkout total={total} setCheckout={setCheckout} payLink={payLink} myList={myList} setMyList={setMyList} paySuccess={paySuccess} />
   </>
   }
   
   </>
    )
}

export default Salon
const salon = [
  {
    "ID": 0,
    "Title": "Soil",
    "Speaker": "with Gemma Seltzer",
    "Date1": "Monday 20 May, 6.30-7.30pm",
    "Date2": "Tuesday 21 May, 7.15-9am, followed by optional writing hour (9-10am)",
    "DateJS": "2024-05-20",
    "Blurb1": "Imagine this first event as a plot of soil, ready for us to plant creative seeds that will come to fruition during our week together. We'll gather to think about how nature forces us to pause and pay attention to the world around us, how it offers us the opportunity to notice small details and chart time, season by season. Join us with a cup of tea (or wine!) to write inspired by the literal—and metaphorical—soil that feeds us.",
    "Blurb2": "For our morning workshop, we'll write about our lives and shape stories from our experiences with patience and care. We'll consider our own individual writing practices, looking at Natalie Goldberg's idea of 'composting.' In her words: “Our bodies are garbage heaps. We collect experience, and from the decomposition of the thrown-out eggshells, spinach leaves, coffee grinds, and old steak bones of our minds come nitrogen, heat, and very fertile soil. But this does not come all at once. It takes time.” ",
    "Booking": true,
    "New": false
  },
  {
    "ID": 1,
    "Title": "Roots",
    "Speaker": "with Marchelle Farrell",
    "Date1": "Tuesday 21 May, 6.30-7.30pm",
    "Date2": "Wednesday 22 May, 7.15-9am, followed by optional writing hour (9-10am)",
    "DateJS": "2024-05-21",
    "Blurb1": "We welcome Marchelle Farrell for an evening exploring the idea of roots, and how it relates to both growing—and writing. Marchelle Farrell, a writer, medical psychotherapist, and amateur gardener, was born in Trinidad and Tobago, but is attempting to become hardy here in the UK. A lyrical, tender writer, her work explores the patterns we reenact in relationship to land, and how they might change. Marchelle's debut book, Uprooting, won the Nan Shepherd Prize for nature writing and is beautifully structured by the seasons, with the garden, plants and flowers slowly flourishing as the story unfolds.",
    "Blurb2": "On Wednesday morning, after (we hope) a peaceful night of rest and dreams, we'll meet for a writing workshop inspired by Marchelle's talk. What grounds us? What connects us to our deepest writing selves? What roots us in our lives—is it a place, a person, or a feeling? In this morning event, we'll consider where we're writing from, the ways we can tend our own creative roots, and marvel at beautiful tree root structures!",
    "Booking": true,
    "New": false
  },
  {
    "ID": 2,
    "Title": "Grow",
    "Speaker": "with Charlotte Mendelson",
    "Date1": "Wednesday 22 May, 6.30-7.30pm",
    "Date2": "Thursday 23 May, 7.15-9am, followed by optional writing hour (9-10am)",
    "DateJS": "2024-05-22",
    "Blurb1": "The wonderful Charlotte Mendelson joins us for an evening event exploring the relationship between growing and creativity. Charlotte is the award-winning author of novels including most recently, The Exhibitionist, which was longlisted for the Women's Prize for Fiction and The Times Novel of the Year. Her gardening memoir Rhapsody in Green, about her tiny, urban garden, is energising and hilarious, in her characteristic style that offers deep, compassionate observations about people, places and relationships.",
    "Blurb2": "We'll wake bright and early to continue thinking about how writing and growing are interlinked. How, as Camille T. Dungy says, “Gardens, history, and hope are the same.” Expect a celebration of cultivating new seedlings and window boxes, keeping houseplants alive, and the beauty of overgrown parks and gardens! Join us to find inspiration in artists and poets who tend their creativity and their flowers at the same time, such as poet W. S. Merwin, who planted something every day for a period of time, to coax his garden back to life.",
    "Booking": true,
    "New": false
  },
  {
    "ID": 3,
    "Title": "Fruit",
    "Speaker": "with Lillie O'Brien",
    "Date1": "Thursday 23 May, 6.30-7.30pm",
    "Date2": "Friday 24 May, 7.15-9am, followed by optional writing hour (9-10am)",
    "DateJS": "2024-05-23",
    "Blurb1": "We'll close our Spring Salon with a fantastic special guest: Lillie O'Brien, who set up London Borough of Jam, following five years as pastry chef at St. John Bread and Wine. Her amazing handmade seasonal all-natural jams include unusual additions such as bay leaf, cardamom and wild fennel pollen. Lillie will join us on Thursday evening to share her jam-making year, which she charts in five distinct seasons. She'll talk about the joy of fruit, jam as meditation and the pleasure of preserving flavours, colour and memories in jars.",
    "Blurb2": "Our final morning will be tasty! We'll feast on writing about eating and cooking. We'll read poems and stories that bring food to life in delightfully evocative ways. Join us to enjoy William Carlos Williams' delicious plums, Aimee Nezhukumatathil's miracle fruit and Chen Chen's fearless mangos! As we move towards a new season, we'll also consider our creative plans and what we'd like to see come to fruition in the summer months. ",
    "Booking": true,
    "New": false
  }
 ]

export default salon
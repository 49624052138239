import { IoIosClose } from 'react-icons/io'
import { useState, useEffect } from 'react'


const Checkout = ({ total, setCheckout, payLink, setPayLink, myList, setMyList, paySuccess }) => {

    const [status, setStatus] = useState('');
    const [success, setSuccess] = useState(false);
    const [email, setEmail] = useState('');
    const [address, setAddress] = useState('');
    const [memcode, setMemcode] = useState('');

    const runMember = e => {

        e.preventDefault();


        if (address.length === 0){
    

            fetch("/sm1/check-member.php", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    Email:email, Code:memcode, Sessions:myList}),
            })
            .then((res) => res.json())
            .then((data) => {
                if (data.Status === 1)
                {
                window.location.href = "#msg";
                setStatus(data.Message);
                setSuccess(true);
                setMyList([]);
                }
                if (data.Status === 2)
                {
                window.location.href = "#msg";
                setStatus(data.Message);
                }
                if (data.Status === 3)
                {
                window.location.href = "#msg";
                setStatus(data.Message);
                }
            })
            .catch((error) => {
                console.log(error);
            });
            
        }

    }

    useEffect(()=> {
       
        if (success === true)
        {
        setEmail('');
        setMemcode('');
        setMyList([]);
        }

        if (paySuccess)
        {
          setSuccess(true);
          setStatus("Thanks for booking - we can't wait to welcome you! Look out for an email that confirms your place. If you don't receive it, contact us at hello@write-and-shine.com.")
        }


    },[status])

    

    return (
        <>
            <div className='waitingpop'>
            <IoIosClose size={40} style={{color:'#797979', position:'absolute', top: '10px', right:'10px', cursor:'pointer'}} onClick={()=>{setCheckout(false); setPayLink('')}}  />
            { !success &&
            <>
                <h4 className='center'>£{total}</h4>
                <p className='sub center'>You will be taken to our Stripe checkout page.</p>
                <a href={`${payLink}`}>
               <button className='btncenter book nomartop'>Pay now</button>
               </a>

       
               <div className='backsand'>
                <h5 className='center'>A member?</h5>
                <p className='sub center'>These sessions are included in our membership subscription. Enter your details here.</p>

                <form onSubmit={ runMember }> 
                    <input className='waiting' type='email' value={email} onChange={(e) => setEmail(e.target.value)} placeholder='Your email' minLength='1' required></input>
                    
                    <input className='waiting' type='text' value={memcode} onChange={(e) => setMemcode(e.target.value)} placeholder='Your membership code' minLength='1' required></input>

                    <input className='waiting address' type='text' value={address} onChange={(e) => setAddress(e.target.value)} placeholder='Your address'></input>
                
                    <button className='btnright checkout' type='submit'>Submit</button>
                </form>
               </div>
               </>
              }

               <div id='msg'></div>

        {
            status && 
            <>
                { success ?
                <div className='success-msg'>
                      <p>{ status }</p>
                     <a href='https://write-and-shine.com/#mailing'><button className='btncenter book nomartop'>Subscribe to our newsletter</button></a>
                </div>
                :
                <div className='error-msg'><p>{ status }</p></div>
                }
            </>
        }
     
            </div>
        </>
    )
}

export default Checkout